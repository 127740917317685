import { useRequireAuth } from '@/lib/sdk/auth/hooks';
import { LoadingSpinner } from '../LoadingSpinner';

interface ProtectedRouteProps {
  children: React.ReactNode;
  redirectTo?: string;
}

export const ProtectedRoute = ({ children, redirectTo }: ProtectedRouteProps) => {
  const { isLoading } = useRequireAuth(redirectTo);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return <>{children}</>;
};