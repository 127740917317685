import { ApiClient } from '@/lib/axios/client';
import type { LoginDto, SignupDto, AuthResponse } from './types';

const BASE_PATH = '/auth';

export class AuthApi {
  static async login(data: LoginDto): Promise<AuthResponse> {
    return await ApiClient.post<AuthResponse>(`${BASE_PATH}/login`, data);
  }

  static async signup(data: SignupDto): Promise<AuthResponse> {
    return await ApiClient.post<AuthResponse>(`${BASE_PATH}/signup`, data);
  }

  static async logout(): Promise<void> {
    await ApiClient.post<void>(`${BASE_PATH}/logout`);
  }

  static async refreshToken(refreshToken: string): Promise<AuthResponse> {
    return await ApiClient.post<AuthResponse>(`${BASE_PATH}/refresh`, { refreshToken });
  }

  static async me(): Promise<AuthResponse> {
    return await ApiClient.get<AuthResponse>(`${BASE_PATH}/me`);
  }
}