import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Select,
  Textarea,
  Switch,
  InputGroup,
  InputRightElement,
  IconButton,
  Text,
  Box,
  HStack,
} from '@chakra-ui/react';
import { MdVisibility, MdVisibilityOff } from 'react-icons/md';
import { useState } from 'react';
import { UseFormRegister, FieldError } from 'react-hook-form';

interface FormFieldProps {
  label: string;
  name: string;
  register: UseFormRegister<any>;
  error?: FieldError;
  type?: 'text' | 'email' | 'password' | 'select' | 'textarea' | 'switch' | 'tel' | 'date';
  options?: { value: string; label: string }[];
  placeholder?: string;
  required?: boolean;
  description?: string;
  maxLength?: number;
  value?: string;
  max?: string;
  onChange?: (value: string) => void;
}

export const FormField = ({
  label,
  name,
  register,
  error,
  type = 'text',
  options = [],
  placeholder,
  required = false,
  description,
  maxLength,
  value,
  max,
  onChange,
}: FormFieldProps) => {
  const [showPassword, setShowPassword] = useState(false);
  const [charCount, setCharCount] = useState(value?.length || 0);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const newValue = e.target.value;
    if (maxLength && newValue.length > maxLength) {
      e.target.value = newValue.slice(0, maxLength);
      return;
    }
    setCharCount(e.target.value.length);
    onChange?.(e.target.value);
  };

  const registerProps = register(name, {
    onChange: handleChange,
    maxLength: maxLength,
  });

  const renderField = () => {
    switch (type) {
      case 'password':
        return (
          <InputGroup>
            <Input
              type={showPassword ? 'text' : 'password'}
              {...registerProps}
              placeholder={placeholder}
              bg="white"
            />
            <InputRightElement>
              <IconButton
                aria-label={showPassword ? 'Hide password' : 'Show password'}
                icon={showPassword ? <MdVisibilityOff size={18} /> : <MdVisibility size={18} />}
                onClick={() => setShowPassword(!showPassword)}
                variant="ghost"
                size="sm"
              />
            </InputRightElement>
          </InputGroup>
        );
      case 'select':
        return (
          <Select {...registerProps} placeholder={placeholder} bg="white">
            {options.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </Select>
        );
      case 'textarea':
        return (
          <Box>
            <Textarea
              {...registerProps}
              placeholder={placeholder}
              bg="white"
              rows={4}
            />
            {maxLength && (
              <Text fontSize="sm" color="gray.500" textAlign="right" mt={1}>
                {charCount}/{maxLength}
              </Text>
            )}
          </Box>
        );
      case 'switch':
        return (
          <Switch
            {...registerProps}
            size="lg"
          />
        );
      case 'date':
        return (
          <Input
            type="date"
            {...registerProps}
            placeholder={placeholder}
            bg="white"
            max={max}
          />
        );  
      default:
        return (
          <Box>
            <Input
              type={type}
              {...registerProps}
              placeholder={placeholder}
              bg="white"
            />
            {maxLength && (
              <Text fontSize="sm" color="gray.500" textAlign="right" mt={1}>
                {charCount}/{maxLength}
              </Text>
            )}
          </Box>
        );
    }
  };

  return (
    <FormControl isInvalid={!!error} isRequired={required}>
      <HStack justify="space-between" mb={description ? 1 : 3}>
        <FormLabel color="#4d3d2d" mb={0}>
          {label}
        </FormLabel>
      </HStack>
      {description && (
        <Text fontSize="sm" color="gray.600" mb={3}>
          {description}
        </Text>
      )}
      {renderField()}
      <FormErrorMessage>{error?.message}</FormErrorMessage>
    </FormControl>
  );
};