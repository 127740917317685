import { ApiClient } from '@/lib/axios/client';
import type { 
  Chatbot,
  CreateChatbotExcelDto,
  CreateChatbotUIDto,
  UpdateChatbotExcelDto,
  UpdateChatbotUIDto,
  ChatbotFilters,
  UpdateSettingsDto,
  ChatbotMode,
} from './types';
import type { PaginatedData } from '@/lib/axios/types';

const BASE_PATH = '/api/v1/chatbots';

export class ChatbotApi {
  static async getAll(filters?: ChatbotFilters): Promise<PaginatedData<Chatbot>> {
    return await ApiClient.get<PaginatedData<Chatbot>>(BASE_PATH, {
      params: filters,
    });
  }

  static async getById(id: string): Promise<Chatbot> {
    return await ApiClient.get<Chatbot>(`${BASE_PATH}/${id}`);
  }

  static async create(mode: ChatbotMode, data: CreateChatbotExcelDto | CreateChatbotUIDto): Promise<Chatbot> {
    return await ApiClient.post<Chatbot>(`${BASE_PATH}`, data, {
      params: { mode },
    });
  }

  static async update(id: string, mode: ChatbotMode, data: UpdateChatbotExcelDto | UpdateChatbotUIDto): Promise<Chatbot> {
    return await ApiClient.patch<Chatbot>(`${BASE_PATH}/${id}`, data, {
      params: { mode },
    });
  }

  static async delete(id: string): Promise<void> {
    await ApiClient.delete<Chatbot>(`${BASE_PATH}/${id}`);
  }

  static async deleteUser(id: string, userId: string): Promise<void> {
    await ApiClient.delete<Chatbot>(`${BASE_PATH}/${id}/team/${userId}`);
  }

  static async updateSettings(id: string, data: UpdateSettingsDto): Promise<Chatbot> {
    return await ApiClient.post<Chatbot>(`${BASE_PATH}/${id}/settings`, data);
  }
}