import { TeamMember } from "@/lib/sdk/team/types"
import { CalendarEvent } from "../calendar/types"

export interface Chatbot {
  bot_type: string
  completed_reps: number
  created_at: string
  data_source: any
  data_source_count: number
  id: string
  is_active: boolean
  org_id: string
  org_name: string
  quick_bot: boolean
  raw_data_sync: boolean
  sheet_columns: number
  sheet_id: string
  sheets_row_id_support: boolean
  short_code: string
  task_count: number
  task_list: TaskProperties[]
  template: string
  title: string
  total_reps: number
  updated_at: string
  stats: ChatbotStats
  team: TeamMember[]
  reminders: CalendarEvent[]
  qr_code: QRCode
  add_users_to_team: boolean
}

export interface TaskProperties {
  id: string
  description: string
  name: string
  task_type: TaskType
  total_repetitions: number
  days: number
  task_actions: TaskAction[]
  task_type_metadata: string
}

export interface TaskRep {
  id: string
  task_id: string
  chatbot_code: string
  task_name: string
  rep_status: number
  rep_type: string
  notes: string
  updated_at: string
  state_changed_at: string
}

export interface QRCode {
  code: string
  qr_image_url: string
  deep_link_url: string
  prefilled_message: string
}

export interface ChatbotStats {
  pending_tasks: number
  completed_tasks: number
}

export enum TemplateType {
  SOP = 'sop',
  FORM = 'form',
}


export interface UpdateSettingsDto {
  enable_quick_bot: boolean;
  enable_raw_data_sync: boolean;
  add_users_to_team: boolean;
} 

export interface ChatbotFilters {
  orgId?: string;
  search?: string;
  offset?: number;
  limit?: number;
  sortBy?: string;
  sortOrder?: 'asc' | 'desc';
}

export interface CreateChatbotExcelDto {
  template: string;
  sheet_id: string;
}

export interface CreateChatbotUIDto {
  title: string;
  template: string;
  tasks: Task[];
}

export interface UpdateChatbotExcelDto {
  template: string;
  sheet_id: string;
}

export interface UpdateChatbotUIDto {
  title: string;
  template: string;
  tasks: Task[];
}

export interface Task {
  id: string;
  name: string;
  description?: string;
  type: string;
  data?: string;
  actions?: TaskAction[];
}

export const types = ['intro','text','number','photo','document','date','location','address','choice','regular','end'] as const
type TypeId = typeof types[number];

export const TaskTypeMap: Record<TypeId, string> = {
  intro: 'on_list_start',
  text: 'text_required',
  number: 'number_required',
  photo: 'photo_required',
  document: 'doc_required',
  date: 'date_required',
  location: 'loc_required',
  address: 'address_required',
  choice: 'choice',
  regular: 'regular',
  end: 'on_list_complete',
} as const;

export enum TaskType {
  INTRO = 'on_list_start',
  TEXT = 'text_required',
  NUMBER = 'number_required',
  PHOTO = 'photo_required',
  DOCUMENT = 'doc_required',
  DATE = 'date_required',
  LOCATION = 'loc_required',
  ADDRESS = 'address_required',
  CHOICE = 'choice',
  REGULAR = 'regular',
  END = 'on_list_complete',
}

export const actions = ['SKIPPABLE','STARTNEW'] as const
type ActionId = typeof actions[number];

export const ActionMap: Record<ActionId, string> = {
  SKIPPABLE: 'SKIP',
  STARTNEW: 'START',
} as const;

export enum TaskAction {
  SKIPPABLE = 'SKIP',
  STARTNEW = 'START',
}

export type ChatbotMode = 'excel' | 'ui';