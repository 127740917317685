import {
  Box,
  Button,
  VStack,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { TemplateType } from '@/lib/sdk/chatbot/types';
import { FormField } from '@/components/Modal/FormField';

const chatbotSchema = z.object({
  template: z.enum(['sop', 'form']),
  sheet_url: z
    .string()
    .url('Please enter a valid URL')
    .includes('docs.google.com/spreadsheets'),
});

const templateOptions = [
  { value: TemplateType.SOP, label: 'Sop' },
  { value: TemplateType.FORM, label: 'Form' },
];

export type ChatbotFormData = z.infer<typeof chatbotSchema>;

interface ChatbotFormProps {
  onSubmit: (data: ChatbotFormData) => void;
}

export const ChatbotForm = ({ onSubmit }: ChatbotFormProps) => {
  const form = useForm<ChatbotFormData>({
    resolver: zodResolver(chatbotSchema),
  });

  return (
    <Box
      bg="#faeed9"
      p={6}
      rounded="md"
      shadow="md"
    >
      <form onSubmit={form.handleSubmit(onSubmit)}>
      <VStack spacing={4}>
        <FormField
            label="Template Type"
            name="template"
            type="select"
            options={templateOptions}
            register={form.register}
            error={form.formState.errors.template}
            required
        />
        <FormField
          label="Google Sheet URL"
          name="sheet_url"
          register={form.register}
          error={form.formState.errors.sheet_url}
          required
          placeholder="https://docs.google.com/spreadsheets/d/..."
        />
        <Button
          type="submit"
          colorScheme="whatsapp"
          size="lg"
          width="full"
          isLoading={form.formState.isSubmitting}
          loadingText="Submitting"
        >
          Create Chatbot
        </Button>
      </VStack>
      </form>
    </Box>
  );
};