export interface PresignedUrlResponse {
  signed_url: string;
  file_url:  string;
  file_name: string;
}

export interface UploadProgress {
  loaded: number;
  total: number;
  percentage: number;
}

export type ProgressCallback = (progress: UploadProgress) => void;

export interface UploadOptions {
  maxSize?: number; // in bytes
  onProgress?: ProgressCallback;
  folder?: string;
}

export interface UploadResult {
  url: string;
  key: string;
}

export class FileValidationError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'FileValidationError';
  }
}