import { useState, useCallback } from 'react';
import { Task } from '@/lib/sdk/chatbot';
import { useFieldArray, UseFormReturn } from 'react-hook-form';
import { ChatbotFormData } from '../types';

export function useTaskListState(form: UseFormReturn<ChatbotFormData>) {
  // Track if a task is being edited
  const [editingTaskId, setEditingTaskId] = useState<string | null>(null);
  
  // Track pending reorder operation
  const [pendingReorder, setPendingReorder] = useState<{
    oldIndex: number;
    newIndex: number;
  } | null>(null);

  const { fields, move, append, remove } = useFieldArray({
    control: form.control,
    name: 'tasks',
  });

  const handleStartEditing = useCallback((taskId: string) => {
    setEditingTaskId(taskId);
  }, []);

  const handleStopEditing = useCallback(() => {
    setEditingTaskId(null);
    // Apply any pending reorder operation
    if (pendingReorder) {
      move(pendingReorder.oldIndex, pendingReorder.newIndex);
      setPendingReorder(null);
    }
  }, [pendingReorder, move]);

  const handleReorder = useCallback((oldIndex: number, newIndex: number) => {
    if (editingTaskId) {
      // Store reorder operation to apply after editing is complete
      setPendingReorder({ oldIndex, newIndex });
    } else {
      // Apply reorder immediately if no task is being edited
      move(oldIndex, newIndex);
    }
  }, [editingTaskId, move]);

  const handleAddTask = useCallback((task: Task) => {
    append(task);
  }, [append]);

  const handleRemoveTask = useCallback((index: number) => {
    const taskId = fields[index]?.id;
    if (taskId === editingTaskId) {
      setEditingTaskId(null);
    }
    remove(index);
  }, [fields, editingTaskId, remove]);

  return {
    fields,
    editingTaskId,
    handleStartEditing,
    handleStopEditing,
    handleReorder,
    handleAddTask,
    handleRemoveTask,
  };
}
