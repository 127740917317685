import {
  HStack,
  IconButton,
  Box,
  VStack,
  CheckboxGroup,
  Checkbox,
  SimpleGrid,
} from '@chakra-ui/react';
import { MdDragIndicator, MdDelete } from 'react-icons/md';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Controller } from 'react-hook-form';
import { FormField } from '@/components/Modal/FormField';
import { TaskField } from './TaskField';
import { useEffect } from 'react';
import { ActionMap } from '@/lib/sdk/chatbot/types';

interface TaskItemProps {
  id: string;
  index: number;
  register: any;
  control: any;
  remove: () => void;
  errors: any;
  isEditing: boolean;
  setValue : any;
  onStartEditing: () => void;
  onStopEditing: () => void;
}

export const TaskItem = ({ 
  id, 
  index, 
  register, 
  control,
  setValue,
  remove, 
  errors,
  isEditing,
  onStartEditing,
  onStopEditing,
}: TaskItemProps) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.5 : 1,
  };

  // Track focus/blur events to manage editing state
  useEffect(() => {
    const handleFocus = () => onStartEditing();
    const handleBlur = (e: FocusEvent) => {
      // Only stop editing if focus moves outside this task item
      if (!e.currentTarget.contains(e.relatedTarget as Node)) {
        onStopEditing();
      }
    };

    const element = document.getElementById(`task-${id}`);
    if (element) {
      element.addEventListener('focusin', handleFocus);
      element.addEventListener('focusout', handleBlur);
      return () => {
        element.removeEventListener('focusin', handleFocus);
        element.removeEventListener('focusout', handleBlur);
      };
    }
  }, [id, onStartEditing, onStopEditing]);

  return (
    <Box
      id={`task-${id}`}
      ref={setNodeRef}
      style={style}
      bg="#faeed9"
      p={4}
      rounded="md"
      shadow="sm"
    >
      <HStack spacing={4} align="flex-start">
        <Box 
          cursor={isEditing ? 'not-allowed' : 'grab'} 
          {...(!isEditing && { ...attributes, ...listeners })}
        >
          <MdDragIndicator size={24} />
        </Box>

        <VStack flex={1} spacing={4} align="stretch">
        <FormField
          label="Task Name"
          name={`tasks.${index}.name`}
          register={register}
          error={errors?.tasks?.[index]?.name}
          required
          placeholder="Enter task name"
          maxLength={24}
        />

        <FormField
          label="Description"
          name={`tasks.${index}.description`}
          register={register}
          error={errors?.tasks?.[index]?.description}
          type="textarea"
          placeholder="Enter task description"
          maxLength={72}
        />

          <TaskField
            index={index}
            register={register}
            control={control}
            setValue={setValue}
            errors={errors}
          />

          <Box flex={1}>
            <Controller
              name={`tasks.${index}.actions`}
              control={control}
              defaultValue={[]}
              render={({ field: { onChange, value } }) => (
                <VStack align="stretch">
                  <Box as="label" fontWeight="medium" color="#4d3d2d">
                    Actions
                  </Box>
                  <CheckboxGroup
                    colorScheme="whatsapp"
                    value={value}
                    onChange={onChange}
                  >
                    <SimpleGrid columns={2} spacing={2}>
                      {Object.entries(ActionMap).map(([key, value]) => (
                        <Checkbox
                          key={key}
                          value={value}
                          borderColor="#4d3d2d"
                          sx={{
                            '[data-checked]': {
                              borderColor: '#25D366',
                              backgroundColor: '#25D366',
                            }
                          }}
                        >
                          {key}
                        </Checkbox>
                      ))}
                    </SimpleGrid>
                  </CheckboxGroup>
                </VStack>
              )}
            />
          </Box>
        </VStack>

        <IconButton
          aria-label="Remove task"
          icon={<MdDelete />}
          onClick={remove}
          variant="ghost"
          colorScheme="red"
        />
      </HStack>
    </Box>
  );
};