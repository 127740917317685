import axios from 'axios';
import { ApiClient } from '@/lib/axios/client';
import { S3_CONFIG } from './config';
import { 
  PresignedUrlResponse, 
  UploadOptions, 
  UploadResult,
  UploadProgress,
  FileValidationError,
} from './types';

/**
 * Validates a file before upload
 * @param file - The file to validate
 * @param maxSize - Maximum allowed file size in bytes
 * @throws {FileValidationError} If validation fails
 */
function validateFile(file: File, maxSize = S3_CONFIG.MAX_SIZE): void {
  // Check file type
  if (!S3_CONFIG.ALLOWED_TYPES.includes(file.type)) {
    throw new FileValidationError(
      `Invalid file type. Allowed types: ${S3_CONFIG.ALLOWED_TYPES.join(', ')}`
    );
  }

  // Check file size
  if (file.size > maxSize) {
    const maxSizeMB = Math.round(maxSize / (1024 * 1024));
    throw new FileValidationError(
      `File size exceeds ${maxSizeMB}MB limit`
    );
  }
}

/**
 * Gets a pre-signed URL for S3 upload
 * @param file - The file to upload
 * @returns Promise with pre-signed URL data
 */
async function getPresignedUrl(file: File, options: UploadOptions): Promise<PresignedUrlResponse> {
  const response = await ApiClient.post<PresignedUrlResponse>(
    S3_CONFIG.PRESIGNED_URL_ENDPOINT,
    {
      storage_bucket: import.meta.env.VITE_S3_BUCKET,
      file_extension: file.name.split('.').pop(),
      storage_folder: options.folder,
    }
  );
  return response;
}

/**
 * Uploads a file directly to S3 using a pre-signed URL with Axios
 * @param url - The pre-signed URL
 * @param formData - FormData with file and fields
 * @param onProgress - Optional progress callback
 * @returns Promise that resolves when upload is complete
 */
async function uploadToS3(
  url: string,
  file: File,
  onProgress?: (progress: UploadProgress) => void
): Promise<void> {
  await axios.put(url, file, {
    headers: {
      'Content-Type': file.type,
    },
    onUploadProgress: (progressEvent) => {
      if (progressEvent.total && onProgress) {
        onProgress({
          loaded: progressEvent.loaded,
          total: progressEvent.total,
          percentage: Math.round((progressEvent.loaded / progressEvent.total) * 100),
        });
      }
    },
  });
}

/**
 * Uploads a file to S3 with progress tracking and validation
 * @param file - The file to upload
 * @param options - Upload options including max size and progress callback
 * @returns Promise with the final S3 URL
 * @throws {FileValidationError} If file validation fails
 * @throws {Error} If upload fails
 */
export async function uploadFileToS3(
  file: File,
  options: UploadOptions = {}
): Promise<UploadResult> {
  try {
    // Validate file
    validateFile(file, options.maxSize);

    // Get pre-signed URL
    const { signed_url,file_url, file_name } = await getPresignedUrl(file, options);

    // Upload to S3 with progress tracking
    await uploadToS3(signed_url, file, options.onProgress);

    // Return the final S3 URL and key
    return {
      url: file_url,
      key: file_name,
    };
  } catch (error) {
    if (error instanceof FileValidationError) {
      throw error;
    }
    throw new Error(
      error instanceof Error ? error.message : 'Upload failed'
    );
  }
}