import { 
  VStack,
  Box,
  Image,
  Progress,
  Link,
  Text,
  Input,
  InputGroup,
  InputRightElement,
  IconButton,
  useToast,
  Tooltip,
} from '@chakra-ui/react';
import { useWatch } from 'react-hook-form';
import { FormField } from '@/components/Modal/FormField';
import { TaskType, TaskTypeMap } from '@/lib/sdk/chatbot/types';
import { useState, useCallback } from 'react';
import { MdUpload, MdHelpOutline } from 'react-icons/md';
import { FileValidationError } from '@/lib/s3/types';
import { uploadFileToS3 } from '@/lib/s3/upload';
import { useParams } from 'react-router-dom';

interface TaskFieldProps {
  index: number;
  register: any;
  control: any;
  errors: any;
  setValue: any;
}

const METADATA_FIELDS = [
  TaskType.LOCATION,
  TaskType.ADDRESS,
  TaskType.CHOICE,
  TaskType.INTRO,
];

const LOCATION_INSTRUCTIONS = `
1. Open Google Maps
2. Right-click on desired location
3. Select coordinates (e.g. 12.3456, 78.9012)
4. It will be copied automatically. Paste it here.
`;

export const TaskField = ({ 
  index,
  register,
  control,
  setValue,
  errors,
}: TaskFieldProps) => {
  const toast = useToast();
  const [uploadProgress, setUploadProgress] = useState(0);
  const [previewUrl, setPreviewUrl] = useState('');
  const { orgId } = useParams();
  const template = useWatch({
    control,
    name: 'template',
  });

  const taskType = useWatch({
    control,
    name: `tasks.${index}.type`,
  });

  const taskData = useWatch({
    control,
    name: `tasks.${index}.data`,
  });

  const taskTypeOptions = Object.entries(TaskTypeMap)
    .filter(([_, value]) => {
      if (template === 'sop') {
        return value === TaskType.REGULAR;
      }
      return true;
    })
    .map(([key, value]) => ({
      value: value,
      label: key,
    }));

  const showMetadata = METADATA_FIELDS.includes(taskType as TaskType);

  // Image upload handler
  const handleImageUpload = useCallback(async (file: File) => {
    try {
      // Start upload with progress tracking
      const { url } = await uploadFileToS3(file, {
        onProgress: (progress) => {
          setUploadProgress(progress.percentage);
        },
        folder: `uploads/${orgId}`,
      });
  
      // Update preview and form
      setPreviewUrl(url);
      setValue(`tasks.${index}.data`, url);
  
      toast({
        title: 'Image uploaded successfully',
        status: 'success',
        duration: 3000,
      });
    } catch (error) {
      // Handle specific validation errors
      if (error instanceof FileValidationError) {
        toast({
          title: 'Invalid file',
          description: error.message,
          status: 'error',
          duration: 3000,
        });
        return;
      }
  
      // Handle general upload errors
      toast({
        title: 'Upload failed',
        description: error instanceof Error ? error.message : 'Unknown error occurred',
        status: 'error',
        duration: 3000,
      });
    } finally {
      // Reset progress on completion or error
      setUploadProgress(0);
    }
  }, [control, index, toast]);

  // Validate coordinates format
  const validateCoordinates = (value: string) => {
    const coordRegex = /^-?\d+(\.\d+)?,\s*-?\d+(\.\d+),\s*\d+?$/;
    return coordRegex.test(value);
  };

  const renderMetadataField = () => {
    switch (taskType) {
      case TaskType.INTRO:
        return (
          <VStack spacing={4} align="stretch">
            <FormField
              label="Brand Image URL"
              name={`tasks.${index}.data`}
              register={register}
              error={errors?.tasks?.[index]?.data}
              placeholder="Enter image URL or upload image"
            />
            
            <Box>
              <Input
                type="file"
                accept="image/*"
                display="none"
                id={`image-upload-${index}`}
                onChange={(e) => {
                  const file = e.target.files?.[0];
                  if (file) handleImageUpload(file);
                }}
              />
              <IconButton
                as="label"
                htmlFor={`image-upload-${index}`}
                aria-label="Upload image"
                icon={<MdUpload />}
                cursor="pointer"
              />
            </Box>

            {uploadProgress > 0 && uploadProgress < 100 && (
              <Progress value={uploadProgress} size="sm" colorScheme="whatsapp" />
            )}

            {previewUrl && (
              <Box mt={2}>
                <Image
                  src={previewUrl}
                  alt="Preview"
                  maxH="200px"
                  objectFit="contain"
                  borderRadius="md"
                />
              </Box>
            )}
          </VStack>
        );

      case TaskType.LOCATION:
        return (
          <VStack spacing={4} align="stretch">
            <InputGroup>
              <Input
                {...register(`tasks.${index}.data`)}
                placeholder="Enter coordinates (e.g. 12.3456, 78.9012, 25)"
                isInvalid={taskData && !validateCoordinates(taskData)}
              />
              <InputRightElement>
                <Tooltip 
                  label={LOCATION_INSTRUCTIONS} 
                  placement="top" 
                  hasArrow
                >
                  <IconButton
                    aria-label="Location help"
                    icon={<MdHelpOutline />}
                    variant="ghost"
                    size="sm"
                  />
                </Tooltip>
              </InputRightElement>
            </InputGroup>
            
            <Link
              href="https://www.google.com/maps"
              target="_blank"
              color="whatsapp.500"
              fontSize="sm"
            >
              Open Google Maps
            </Link>

            {taskData && !validateCoordinates(taskData) && (
              <>
              <Text color="red.500" fontSize="md">
                Invalid coordinates format. Use format: latitude, longitude, radius
              </Text>
              <Text color="red.500" fontSize="md">
                If verification is not required then set the radius to 0
              </Text>
              </>
            )}
          </VStack>
        );

      default:
        return (
          <FormField
            label="Additional Information"
            name={`tasks.${index}.data`}
            register={register}
            error={errors?.tasks?.[index]?.data}
            type="text"
            placeholder={getMetadataPlaceholder(taskType as TaskType)}
          />
        );
    }
  };

  if (template === 'sop' && taskType !== TaskType.REGULAR) {
    setTimeout(() => {
      setValue(`tasks.${index}.type`, TaskType.REGULAR);
    });
  }

  return (
    <VStack spacing={4} align="stretch">
      <FormField
        label="Task Type"
        name={`tasks.${index}.type`}
        register={register}
        error={errors?.tasks?.[index]?.type}
        type="select"
        options={taskTypeOptions}
        required
      />

      {showMetadata && renderMetadataField()}
    </VStack>
  );
};

function getMetadataPlaceholder(type: TaskType): string {
  switch (type) {
    case TaskType.LOCATION:
      return "Enter coordinates (e.g. 12.3456, 78.9012)";
    case TaskType.ADDRESS:
      return "Enter address format or validation rules";
    case TaskType.CHOICE:
      return "Enter choices, one per line";
    case TaskType.INTRO:
      return "Enter image URL or upload image";
    default:
      return "Enter additional information";
  }
}