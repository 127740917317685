import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { ChatbotApi } from './api';
import type { 
  CreateChatbotExcelDto,
  CreateChatbotUIDto,
  UpdateChatbotExcelDto,
  UpdateChatbotUIDto,
  ChatbotFilters,
  UpdateSettingsDto,
  ChatbotMode,
} from './types';

const QUERY_KEYS = {
  all: ['chatbots'] as const,
  list: (filters?: ChatbotFilters) => [...QUERY_KEYS.all, 'list', filters] as const,
  detail: (id: string) => [...QUERY_KEYS.all, 'detail', id] as const,
};

export const useChatbots = (filters?: ChatbotFilters) => {
  return useQuery({
    queryKey: QUERY_KEYS.list(filters),
    queryFn: () => ChatbotApi.getAll(filters),
    enabled: !!filters?.orgId,
  });
};

export const useChatbot = (id: string) => {
  return useQuery({
    queryKey: QUERY_KEYS.detail(id),
    queryFn: () => ChatbotApi.getById(id),
    enabled: !!id,
  });
};

export const useCreateChatbot = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ mode, data }: { 
      mode: ChatbotMode; 
      data: CreateChatbotExcelDto | CreateChatbotUIDto;
    }) => ChatbotApi.create(mode, data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: QUERY_KEYS.all });
    },
  });
};

export const useUpdateChatbot = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ id, mode, data }: { 
      id: string; 
      mode: ChatbotMode;
      data: UpdateChatbotExcelDto | UpdateChatbotUIDto;
    }) => ChatbotApi.update(id, mode, data),
    onSuccess: (chatbot) => {
      queryClient.invalidateQueries({ queryKey: QUERY_KEYS.detail(chatbot.id) });
      queryClient.invalidateQueries({ queryKey: QUERY_KEYS.all });
    },
  });
};

export const useDeleteChatbot = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (id: string) => ChatbotApi.delete(id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: QUERY_KEYS.all });
    },
  });
};

export const useDeleteChatbotUser = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (id: string) => ChatbotApi.delete(id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: QUERY_KEYS.all });
    },
  });
};

export const useChatbotSettings = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ id, data }: { id: string; data: UpdateSettingsDto }) => ChatbotApi.updateSettings(id, data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: QUERY_KEYS.all });
    },
  });
};