import { VStack } from '@chakra-ui/react';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragEndEvent,
} from '@dnd-kit/core';
import {
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { TaskItem } from './TaskItem';
import { useTaskListState } from './hooks/useTaskListState';
import { UseFormReturn } from 'react-hook-form';
import { ChatbotFormData } from './types';

interface TaskListProps {
  form: UseFormReturn<ChatbotFormData>;
  errors: any;
}

export const TaskList = ({ form, errors }: TaskListProps) => {
  const {
    fields,
    editingTaskId,
    handleStartEditing,
    handleStopEditing,
    handleReorder,
    handleRemoveTask,
  } = useTaskListState(form);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      // Prevent drag start while editing
      activationConstraint: {
        distance: editingTaskId ? Infinity : 8,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    
    if (over && active.id !== over.id) {
      const oldIndex = fields.findIndex(task => task.id === active.id);
      const newIndex = fields.findIndex(task => task.id === over.id);
      handleReorder(oldIndex, newIndex);
    }
  };

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
    >
      <SortableContext
        items={fields.map(task => task.id)}
        strategy={verticalListSortingStrategy}
      >
        <VStack spacing={4} align="stretch">
          {fields.map((task, index) => (
            <TaskItem
              key={task.id}
              id={task.id}
              index={index}
              register={form.register}
              control={form.control}
              setValue={form.setValue}
              remove={() => handleRemoveTask(index)}
              errors={errors}
              isEditing={task.id === editingTaskId}
              onStartEditing={() => handleStartEditing(task.id)}
              onStopEditing={handleStopEditing}
            />
          ))}
        </VStack>
      </SortableContext>
    </DndContext>
  );
};