import {
  Box,
  Button,
  VStack,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { MdAdd } from 'react-icons/md';
import { TaskList } from './TaskList';
import { type ChatbotFormData } from './types';
import { FormField } from '@/components/Modal/FormField';
import { Xid } from 'xid-ts';
import { TaskType } from '@/lib/sdk/chatbot/types';

const formSchema = z.object({
  title: z.string()
    .min(1, 'Title is required')
    .max(55, 'Title must be 55 characters or less'),
  template: z.string().min(1, 'Template is required'),
  tasks: z.array(z.object({
    id: z.string(),
    name: z.string()
      .min(1, 'Task name is required')
      .max(24, 'Task name must be 24 characters or less'),
    description: z.string()
      .max(72, 'Description must be 72 characters or less')
      .optional(),
    type: z.string(),
    data: z.string().optional(),
    actions: z.array(z.string()).optional(),
  })),
});

interface ChatbotFormProps {
  onSubmit: (data: ChatbotFormData) => void;
  initialData?: ChatbotFormData;
  isLoading: boolean;
}

const templateOptions = [
  { value: 'sop', label: 'SOP' },
  { value: 'form', label: 'Form' },
];

export const ChatbotForm = ({ onSubmit, initialData, isLoading }: ChatbotFormProps) => {
  const form = useForm<ChatbotFormData>({
    resolver: zodResolver(formSchema),
    defaultValues: initialData || {
      title: '',
      template: '',
      tasks: [],
    },
  });

  const handleAddTask = () => {
    const newTask = {
      id: new Xid().toString(),
      name: '',
      description: '',
      type: TaskType.TEXT,
      actions: [],
    };
    form.setValue('tasks', [...(form.getValues('tasks') || []), newTask]);
  };

  return (
    <Box 
      as="form" 
      onSubmit={form.handleSubmit(onSubmit)}
      bg="#faeed9"
      p={6}
      rounded="md"
      shadow="md"
    >
      <VStack spacing={6} align="stretch">
        <FormField
          label="Title"
          name="title"
          register={form.register}
          error={form.formState.errors.title}
          required
          placeholder="Enter chatbot title"
          maxLength={55}
        />

        <FormField
          label="Template Type"
          name="template"
          type="select"
          options={templateOptions}
          register={form.register}
          error={form.formState.errors.template}
          required
        />

        <Box>
          <TaskList
            form={form}
            errors={form.formState.errors}
          />

          <Button
            leftIcon={<MdAdd />}
            onClick={handleAddTask}
            mt={4}
            variant="ghost"
          >
            Add Task
          </Button>
        </Box>

        <Button 
          type="submit" 
          colorScheme="whatsapp" 
          isLoading={isLoading}
          loadingText={initialData ? 'Updating...' : 'Creating...'}>
          {initialData ? 'Update' : 'Create'} Chatbot
        </Button>
      </VStack>
    </Box>
  );
};